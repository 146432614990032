import { GetServerSideProps } from "next"
import { useTranslation, withTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { Params } from "next/dist/shared/lib/router/utils/route-matcher"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect } from "react"

const TIME_REDIRECT_TO_HOME = 3000
const FourOhFour = () => {
  const { t } = useTranslation()
  const router = useRouter()

  // After some seconds, auto redirect to home
  useEffect(() => {
    const timeout = setTimeout(() => {
      router.push("/")
    }, TIME_REDIRECT_TO_HOME)
    return () => {
      clearTimeout(timeout)
    }
  }, [router])

  return (
    <>
      <section className="flex items-center h-full p-16">
        <div className="flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <h2 className="mb-8 Nunito-800 text-9xl text-main-01">
              <span className="sr-only">Error</span>404
            </h2>
            <p className="text-2xl font-semibold md:text-3xl">
              Sorry, we couldn&apos;t find this page.
            </p>
            <p className="mt-4 mb-8 dark:text-gray-400">
              But dont worry, you can find plenty of other things on our
              homepage.
            </p>
            <Link
              href="/"
              className="px-8 py-3 font-semibold rounded bg-main-01 text-[#fff]"
            >
              {t("common.back_to_home")}
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export const getStaticProps: GetServerSideProps = async ({
  locale
}: Params) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"]))
    }
  }
}

export default withTranslation("common")(FourOhFour)
